import React, { useState } from "react";
import { Input, Button, message } from "antd";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    transportType: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate phoneNumber (only numbers, +, -, spaces allowed)
    if (name === "phoneNumber") {
      const allowedChars = /^[0-9+\-\s]*$/;
      if (!allowedChars.test(value)) {
        return;
      }
    }

    // Validate name (only letters allowed)
    if (name === "name") {
      const namePattern = /^[a-zA-Z\s]*$/;
      if (!namePattern.test(value)) {
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Name is required";
    if (!formData.phoneNumber) formErrors.phoneNumber = "Phone number is required";
    if (!formData.transportType) formErrors.transportType = "Transport type is required";
    if (!formData.subject) formErrors.subject = "Subject is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch(`${baseURL}/api/createLead`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        message.success("Message sent successfully!");
        setFormData({
          name: "",
          phoneNumber: "",
          transportType: "",
          subject: "",
          message: "",
        });
      } else {
        const data = await response.json();
        message.error(data.error || "Failed to send message.");
      }
    } catch (error) {
      message.error("An error occurred while sending the message.");
    }
  };

  return (
    <div
      className="max-w-xl mx-auto p-8 mt-10 border-t"
      style={{ borderTopColor: "#ccc" }}
    >
      <h3 className="text-center text-gray-700 text-sm font-medium mb-2">
        CONTACT US
      </h3>
      <h1 className="text-center text-4xl font-semibold mb-8">
        Drop us a line
      </h1>

      <div className="space-y-4">
        <div>
          <Input
            name="name"
            placeholder="Your Name"
            className="border-gray-300 rounded-md h-12 hover:border-yellow-400 focus:border-yellow-400 transition duration-200"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <p className="text-left text-red-500 text-sm mt-1">{errors.name}</p>}
        </div>

        <div>
          <Input
            name="phoneNumber"
            placeholder="Phone Number"
            className="border-gray-300 rounded-md h-12 hover:border-yellow-400 focus:border-yellow-400 transition duration-200"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          {errors.phoneNumber && <p className="text-left text-red-500 text-sm mt-1">{errors.phoneNumber}</p>}
        </div>

        <div className="flex space-x-4">
          <div className="flex-grow">
            <Input
              name="transportType"
              placeholder="Transport Type"
              className="border-gray-300 rounded-md h-12 flex-grow hover:border-yellow-400 focus:border-yellow-400 transition duration-200"
              value={formData.transportType}
              onChange={handleChange}
            />
            {errors.transportType && <p className="text-left text-red-500 text-sm mt-1">{errors.transportType}</p>}
          </div>

          <div className="flex-grow">
            <Input
              name="subject"
              placeholder="Subject"
              className="border-gray-300 rounded-md h-12 flex-grow hover:border-yellow-400 focus:border-yellow-400 transition duration-200"
              value={formData.subject}
              onChange={handleChange}
            />
            {errors.subject && <p className="text-left text-red-500 text-sm mt-1">{errors.subject}</p>}
          </div>
        </div>

        <Input.TextArea
          name="message"
          placeholder="Message"
          className="border-gray-300 rounded-md hover:border-yellow-400 focus:border-yellow-400 transition duration-200"
          rows={6}
          value={formData.message}
          onChange={handleChange}
        />

        <Button
          type="primary"
          className="bg-yellow-400 text-white h-12 w-full mt-4 font-semibold hover:border-yellow-400 focus:border-yellow-400 transition duration-200"
          onClick={handleSubmit}
        >
          SEND MESSAGE
        </Button>
      </div>
    </div>
  );
};

export default ContactForm;
