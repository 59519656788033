import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Services from "./main/services";
import Contact from "./main/Contact";
import Home from "./main/home";
import Booking from "./main/Booking";
import About from "./main/About";

const Index = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/booking/:serviceName" element={<Booking />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
      </Routes>
    </Router>
  );
};

export default Index;
