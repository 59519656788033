import React from "react";

const data = [
  {
    image: "./assets/icons/shipment-1.svg",
    count: "30+",
    tag: "Hospital Shifting",
  },
  {
    image: "./assets/icons/shipment-2.svg",
    count: "100+",
    tag: "Office shifting",
  },
  {
    image: "./assets/icons/shipment-3.svg",
    count: "400+",
    tag: "House shifting",
  },
  { image: "./assets/icons/stats-4.svg", count: 287, tag: "Happy Client " },
];

const StatCard = ({ image, count, tag }) => (
  <div className="stat-card flex items-end gap-4">
    <img src={image} alt={tag} className="w-20 h-20" />
    <div className="flex flex-col gap-1 items-start">
      <h3 className="text-2xl font-bold">{count}</h3>
      <p className="text-black text-base">{tag}</p>
    </div>
  </div>
);

const Stats = () => {
  return (
    <div className="stats-container py-12 bg-primary">
      <div
        className="stats-wrapper w-10/12 flex mx-auto justify-between gap-8 ">
        {data.map((item, index) => (
          <StatCard
            key={index}
            image={item.image}
            count={item.count}
            tag={item.tag}
            className='stat-card'
          />
        ))}
      </div>
    </div>
  );
};

export default Stats;
