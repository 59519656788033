import React from "react";
import img1 from "../../../Assests/service-01-new-800x650.jpg";
import img2 from "../../../Assests/service-02-new-800x650.jpg";
import img3 from "../../../Assests/service-05-new-800x650.jpg";
import img4 from "../../../Assests/service-04-new-800x650.jpg";

const shipment_1 = "./assets/icons/shipment-1.svg";
const shipment_2 = "./assets/icons/shipment-2.svg";
const shipment_3 = "./assets/icons/shipment-3.svg";
const shipment_4 = "./assets/icons/shipment-4.svg";

const ServiceCard = ({
  image,
  title,
  subtitle,
  description,
  buttonText,
  icon,
  link,
  reverse = false,
}) => (
  <div
    className={`flex items-center bg-gray-800 text-white rounded-lg w-[980px] ${
      reverse ? "flex-row-reverse" : "flex-row"
    }`}
  >
    {/* Hide image on mobile view and show on larger screens */}
    <div className="w-1/2 hidden md:block">
      <img
        src={image}
        alt={title}
        className="w-full h-[300px] object-cover left-image"
      />
    </div>
    <div className="flex flex-col items-center justify-center text-center p-6 bg-gray-800 w-full md:w-1/2">
      <div className="flex flex-col items-center justify-center mb-4">
        <img
          src={icon}
          alt={"icon"}
          className="w-14 h-15 mb-4 filter brightness-0 invert"
        />
        <h3 className="text-lg font-semibold text-yellow-400">{title}</h3>
        <h4 className="text-xl font-bold text-white">{subtitle}</h4>
      </div>
      <p className="text-sm text-gray-400 mb-6">{description}</p>
      <button className="bg-yellow-400 text-gray-800 py-2 px-4 rounded text-sm font-semibold">
        <a href={link}> {buttonText} </a>
      </button>
    </div>
  </div>
);

const ServicesCard = () => {
  return (
    <div className="service-card-main flex justify-center mt-28 px-5">
      <div className="services-card-container grid grid-cols-1 gap-6">
        <ServiceCard
          image={img3}
          title="Residential Moving"
          subtitle="House shifting"
          description="Seamless relocation for your cherished home and belongings."
          buttonText="Book Now"
          link="/booking/house-shifting"
          icon={shipment_3}
        />
        <ServiceCard
          image={img2}
          title="Local Moving"
          subtitle="Office shifting"
          description="Streamlined office transitions for minimal business disruption."
          buttonText="Book Now"
          link="/booking/office-shifting"
          icon={shipment_2}
          reverse
        />
        <ServiceCard
          image={img1}
          title="Commercial Movers"
          subtitle="Hospital Shifting"
          description="Efficient hospital moves ensuring uninterrupted patient care."
          buttonText="Book Now"
          link="/booking/hospital-shifting"
          icon={shipment_1}
        />

        <ServiceCard
          image={img4}
          title="International Moving"
          subtitle="Transportation"
          description="Reliable and efficient transportation for all your needs."
          buttonText="Book Now"
          link="#"
          icon={shipment_4}
          reverse
        />
      </div>
    </div>
  );
};

export default ServicesCard;
