import React from "react";

const Banner = () => {
  return (
    <>
      <div className="banner-container" id="HomeBanner">
        <div className="banner-wrapper">
          <div className="banner-image">
            <img src="./assets/images/banner-image.jpg" alt="" />
          </div>
          <div className="banner-content">
            <h2>
              Quotes in Minutes <br /> <strong>It's That Simple</strong>
            </h2>
            <ul>
              <li>
                <img src="./assets/icons/checkbox.svg" alt="icon" />
                <span> We can pre pack your products </span>
              </li>
              <li>
                <img src="./assets/icons/checkbox.svg" alt="icon" />
                <span> We can assemble your product </span>
              </li>
              <li>
                <img src="./assets/icons/checkbox.svg" alt="icon" />
                <span>We can wrap, packand palletise products. </span>
              </li>
            </ul>
            <div className="pt-8 flex gap-4 button-container">
              <button class="bg-primary text-black font-semibold py-3 px-4  hover:text-white">
                <a href="#services">VIEW MORE</a>
              </button>
              {/* <button class="bg-primary text-black font-semibold  py-3 px-4 hover:text-white">
                LEARN MORE
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
