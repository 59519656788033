import React from "react";
import Banner from "../../../Assests/background banner.jpg";
import LeftImage from "../../../Assests/left image.jpg";
import RightImage from "../../../Assests/Right image.jpg";

const AboutUs = () => {
  return (
    <div>
      <div className="relative mt-[92px] h-[330px] bg-[#FFDC39] bg-cover bg-center services-main-container">
        {/* Banner Section */}
        <div className="absolute inset-0">
          <img
            src={Banner}
            alt="banner"
            className="w-full mb-5 banner-services-cointainer"
          />
          <div className="absolute top-[40%] left-[12%] flex flex-col text-left text-black">
            <h1 className="text-[36px] mb-[13px] font-semibold">About Us</h1>
            <p className="text-[16px] font-medium text-[#777] hover:text-black">
              Your Journey, Our Commitment. <strong>About Us</strong>
            </p>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col md:flex-row items-center justify-between px-[10%] py-[60px] gap-2 mt-24 m-5">
        {/* Left Section (Text + Left Image) */}
        <div className="w-full md:w-6/12 flex flex-col">
          <div className="text-left">
            <h3 className="text-[#232323] text-[16px] font-semibold mb-[10px] uppercase relative inline-block">
              Our Company
              <span className="absolute left-0 bottom-[-5px] w-[120px] h-[2.5px] bg-[#Ffdc39]"></span>
            </h3>
            <h2 className="text-[40px] leading-tight text-[#232323] font-normal mb-[24px]">
              We are a India based one of the top{" "}
              <span className="font-semibold">Mover company</span>
            </h2>
          </div>

          <p className="text-[16px] text-[#777] mb-[30px] leading-[1.6] text-left">
            Moving You with Care, Across Every Mile India’s Top Choice for
            Stress-Free Relocations.
          </p>
          <div className="w-full">
            <img
              src={LeftImage}
              alt="left side image"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>

        {/* Right Section (Right Image + List) */}
        <div className="w-full md:w-5/12 flex flex-col items-start">
          <div className="w-full mb-4">
            <img
              src={RightImage}
              alt="right side image"
              className="w-full h-auto object-cover"
            />
          </div>
          <div className="text-[18px] mt-4">
            <p className="font-semibold text-[#232323] mb-[12px] text-left">
              What We do
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-2">
              <ul className="list-none space-y-2">
                <li className="flex items-start text-[14px] text-[#777] leading-[1.8]">
                  <span className="text-[#FFDC39] mr-2">✔</span> Secure Storage
                  Solutions
                </li>
                <li className="flex items-start text-[14px] text-[#777] leading-[1.8]">
                  <span className="text-[#FFDC39] mr-2">✔</span> Vehicle
                  Transportation
                </li>
                <li className="flex items-start text-[14px] text-[#777] leading-[1.8]">
                  <span className="text-[#FFDC39] mr-2">✔</span>Customized
                  Moving Plans
                </li>
              </ul>
              <ul className="list-none space-y-2">
                <li className="flex items-start text-[14px] text-[#777] leading-[1.8]">
                  <span className="text-[#FFDC39] mr-2">✔</span> Corporate
                  Relocation Services
                </li>
                <li className="flex items-start text-[14px] text-[#777] leading-[1.8]">
                  <span className="text-[#FFDC39] mr-2">✔</span>
                  Long-Distance Relocations
                </li>
                <li className="flex items-start text-[14px] text-[#777] leading-[1.8]">
                  <span className="text-[#FFDC39] mr-2">✔</span> Packing and
                  Unpacking
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
