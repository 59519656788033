import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PlaceAutocomplete from "../../../api/GooglePlacesAutocomplete";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Spin } from "antd";

const Booking = () => {
  const { serviceName } = useParams(); // Get the service name from the URL
  const [startLocation, setStartLocation] = useState(null);
  const [endLocation, setEndLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = "KR Logistics-Bookings";
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    date: "",
    phoneNumber: "",
    startLocation: "",
    startFloor: "",
    liftAvailableStart: false,
    endLocation: "",
    endFloor: "",
    liftAvailableEnd: false,
  });

  const navigate = useNavigate();

  const handleStartLocation = (place) => {
    setStartLocation(place);
    setFormData((prevData) => ({
      ...prevData,
      startLocation: place.place,
    }));
    console.log("Selected place:", place.place);
  };

  const handleEndLocation = (place) => {
    setEndLocation(place);
    setFormData((prevData) => ({
      ...prevData,
      endLocation: place.place,
    }));
    console.log("Selected place:", place.place);
  };

  const today = new Date().toISOString().split("T")[0];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    // Restrict phoneNumber input to numbers, +, -, and spaces
    if (name === "phoneNumber") {
      const allowedChars = /^[0-9+\-\s]*$/;
      if (!allowedChars.test(value)) {
        return;
      }
    }
  
    // Restrict name input to letters and spaces
    if (name === "name") {
      const namePattern = /^[a-zA-Z\s]*$/;
      if (!namePattern.test(value)) {
        return;
      }
    }
  
    // Format the date if the name is "date"
    let updatedValue = value;
    if (name === "date") {
      console.log(value , "test date"); // Logs the unformatted date
      updatedValue = value.split('-').reverse().join('-'); // Format to dd-mm-yyyy
    }
  
    // Update formData state
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : updatedValue,
    }));
  };


  
  // console.log(formData);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const baseURL = process.env.REACT_APP_API_BASE_URL;
    let endpoint;

    switch (serviceName) {
      case "house-shifting":
        endpoint = "house-shifting-form";
        break;
      case "office-shifting":
        endpoint = "office-shifting-form";
        break;
      case "hospital-shifting":
        endpoint = "hospital-shifting-form";
        break;
      default:
        endpoint = "";
        break;
    }

    if (!endpoint) {
      alert("Invalid service type selected.");
      setLoading(false);
      return;
    }

    // console.log(formData);
    



    const apiUrl = `${baseURL}/api/kr-form/${endpoint}`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Error while booking appointment.");
      }

      const data = await response.json();


      
      // Show SweetAlert popup
      Swal.fire({
        title: "Booking Successful",
        text: "You will be redirected once you click OK",
        icon: "success",
      }).then(() => {
        clearTimeout(timeoutId); // Clear the timeout to avoid double redirection
        window.location.href = "/";
      });

      // Set a timeout to redirect after 5 seconds
      const timeoutId = setTimeout(() => {
        Swal.close(); // Close the SweetAlert popup before redirecting
        window.location.href = "/";
      }, 5000);
    } catch (error) {
      console.error(error.message);
      alert("An error occurred. Please try again.");
    }
  };
  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="min-h-screen pt-28">
      <div className="bg-white rounded-lg w-3/4 mx-auto">
        <form className="p-6" onSubmit={handleSubmit}>
          <div className="mb-4">
            <h2 className="text-4xl w-fit mx-auto mb-12">
              <strong>{capitalizeWords(serviceName.replace("-", " "))}</strong>
            </h2>
          </div>

          {/* Customer Details */}
          <div className="mb-12">
            <h3 className="text-lg font-semibold mb-4 text-left">
              Customer Details
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 text-start">
                  <span className="text-red-500">*</span>Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 text-start">
                  <span className="text-red-500">*</span>Email
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 text-start">
                  <span className="text-red-500">*</span>Phone Number
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 text-start">
                  <span className="text-red-500">*</span>Appointment Date
                </label>
                <input
                  type="date"
                  name="date"
                  // value={formData.date}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  min={today} // Disable previous dates
                  required
                />
              </div>
            </div>
          </div>

          {/* Start Address */}
          <div className="mb-12">
            <h3 className="text-lg font-semibold mb-4 text-left">
              Start Address
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 text-start">
                  <span className="text-red-500">*</span>Location
                </label>
                {/* <select
                  name="startLocation"
                  value={formData.startLocation}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                > */}
                <PlaceAutocomplete
                  onSelectPlace={handleStartLocation}
                  value={formData.startLocation}
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 text-start">
                  <span className="text-red-500">*</span>Floor/Flat Number
                </label>
                <input
                  type="text"
                  name="startFloor"
                  placeholder="Floor/Flat Number"
                  value={formData.startFloor}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label className="flex items-center mt-4">
                  <span className="block text-sm font-medium text-gray-700 text-start">
                    <span className="text-red-500">*</span>Lift Availability
                  </span>
                  <input
                    type="checkbox"
                    name="liftAvailableStart"
                    checked={formData.liftAvailableStart}
                    onChange={handleChange}
                    className="ml-2 h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                </label>
              </div>
            </div>
          </div>

          {/* End Address */}
          <div className="mb-12">
            <h3 className="text-lg font-semibold mb-4 text-left">
              End Address
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 text-start">
                  <span className="text-red-500">*</span>Location
                </label>
                <PlaceAutocomplete onSelectPlace={handleEndLocation} required />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 text-start">
                  <span className="text-red-500">*</span>Floor/Flat Number
                </label>
                <input
                  type="text"
                  name="endFloor"
                  placeholder="Floor/Flat Number"
                  value={formData.endFloor}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label className="flex items-center mt-4">
                  <span className="block text-sm font-medium text-gray-700 text-start">
                    <span className="text-red-500">*</span>Lift Availability
                  </span>
                  <input
                    type="checkbox"
                    name="liftAvailableEnd"
                    checked={formData.liftAvailableEnd}
                    onChange={handleChange}
                    className="ml-2 h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                </label>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div>
            {loading && (
              <div className="text-center mb-4">
                <Spin size="small" />
              </div>
            )}
            <button
              type="submit"
              className="w-fit py-2 px-4 bg-blue text-white rounded-md hover:bg-blue-600"
              href="#HomeBanner"
            >
              Send Query
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Booking;
