import React, { useEffect } from 'react';
import ServicesMain from "./Services";
import ServicesCard from "./ServicesCard";
import "./Services.css";

const Services = () => {
  useEffect(() => {
    document.title = "KR Logistics-Services";
  }, []);
  return (
    <div>
      <div>
        <ServicesMain />
      </div>
      <div>
        <ServicesCard />
      </div>
    </div>
  );
};

export default Services;
