import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const mapStyles = {
  height: "400px",
  width: "100%",
  border: "0",
};

const ContactMain = () => {
  return (
    <div className="flex flex-col items-center space-y-8 mt-24">
      {/* Google Map Section */}
      <div className="w-full">
        {/* <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3231.7427704779867!2d-117.92124038474262!3d33.81209468066961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcd7e4f4c3bc6b%3A0x4edec9c909b7e41d!2sDisneyland!5e0!3m2!1sen!2sus!4v1595122865967!5m2!1sen!2sus"
          style={mapStyles}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        /> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30437.663895594662!2d78.35557807551727!3d17.52144763685282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb8dff28839e11%3A0x30e1ac4d7d83c8af!2sNizampet%2C%20Hyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1725603524928!5m2!1sen!2sin"
          style={mapStyles}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>

      {/* Contact Information Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-screen-lg mx-auto text-center">
        <div className="flex flex-col items-center">
          <div className="bg-yellow-500 p-4 rounded-full">
            <FaMapMarkerAlt size={30} className="text-white" />
          </div>
          <h3 className="text-xl font-semibold mt-4">Loaction</h3>
          <p className="text-gray-500">
            Nizampet, Kukatpally, Telangana, India
          </p>
        </div>
        <div className="flex flex-col items-center">
          <div className="bg-yellow-500 p-4 rounded-full">
            <FaPhoneAlt size={30} className="text-white" />
          </div>
          <h3 className="text-xl font-semibold mt-4">Phone Number</h3>
          <p className="text-gray-500">063057 03206</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="bg-yellow-500 p-4 rounded-full">
            <FaEnvelope size={30} className="text-white" />
          </div>
          <h3 className="text-xl font-semibold mt-4">Email Address</h3>
          <p className="text-gray-500">krpackersandmovers369@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default ContactMain;
