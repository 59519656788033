import React from "react";
import { FaRegThumbsUp } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { PiPackageDuotone } from "react-icons/pi";

const Aboutuscard = () => {
  return (
    <div className="text-center py-3">
      <h2 className="text-[#232323] text-[16px] font-semibold mb-[10px] uppercase relative inline-block">
        FUN FACTS
        <span className="absolute left-0 bottom-[-5px] w-[90px] h-[2.5px] bg-[#Ffdc39]"></span>
      </h2>
      <h1 className="text-3xl font-normal text-black-400 mt-2 mb-6">
        We Love Our Clients <br /> And{" "}
        <span className="text-black font-semibold">They Love Us</span>
      </h1>
      <div className="flex justify-around mt-8 p-3 About-Fun-Facts">
        <div className="flex flex-col items-center">
          <TbTruckDelivery className="text-yellow-500 text-6xl mb-4" />
          <h2 className="text-6xl font-bold text-gray-800">500+</h2>
          <p className="text-gray-600 mt-2">Customer Choose Company Movers</p>
        </div>
        <div className="border-l border-gray-300 mx-8"></div>
        <div className="flex flex-col items-center">
          <PiPackageDuotone className="text-yellow-500 text-6xl mb-4" />
          <h2 className="text-6xl font-bold text-gray-800">1200+</h2>
          <p className="text-gray-600 mt-2">Boxes are Successfully Moved</p>
        </div>
        <div className="border-l border-gray-300 mx-8"></div>
        <div className="flex flex-col items-center">
          <FaRegThumbsUp className="text-yellow-500 text-6xl mb-4" />
          <h2 className="text-6xl font-bold text-gray-800">400+</h2>
          <p className="text-gray-600 mt-2">
            Customers are Satisfied with our Service
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aboutuscard;
