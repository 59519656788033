import React, { useState, useEffect } from "react";

const PlaceAutocomplete = ({ onSelectPlace, initialAddress }) => {
  const [input, setInput] = useState(initialAddress);
  const [suggestions, setSuggestions] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setInput(initialAddress);
  }, [initialAddress]);

  useEffect(() => {
    const fetchSuggestions = async (input) => {
      const apiKey = process.env.REACT_APP_MAP_KEY; // Replace with your actual API key
      const url = "https://places.googleapis.com/v1/places:autocomplete";

      try {
        setList([]);
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": apiKey,
          },
          body: JSON.stringify({
            input,
            locationBias: {
              circle: {
                center: {
                  latitude: 37.7937,
                  longitude: -122.3965,
                },
                radius: 500.0,
              },
            },
          }),
        });

        const data = await response.json();
        if (data.suggestions) {
          setSuggestions(data.suggestions);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    if (input) {
      fetchSuggestions(input);
    }
  }, [input]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const fetchPlaceDetails = async (placeId) => {
    const apiKey = process.env.REACT_APP_MAP_KEY; // Replace with your actual API key
    const url = `https://places.googleapis.com/v1/places/${placeId}?fields=address_components,location&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data) {
        const addressComponents = data.addressComponents || [];
        const location = data.location || {};

        let state = "";
        let city = "";
        let pincode = "";
        let country = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("administrative_area_level_1")) {
            state = component.longText;
          }
          if (component.types.includes("locality")) {
            city = component.longText;
          }
          if (component.types.includes("postal_code")) {
            pincode = component.longText;
          }
          if (component.types.includes("country")) {
            country = component.longText;
          }
        });

        const lat = location.latitude ? location.latitude : null;
        const lng = location.longitude ? location.longitude : null;

        return { state, city, pincode, lat, lng, country };
      }
    } catch (error) {
      console.error("Error fetching place details:", error);
    }

    return null;
  };

  const handleSelect = async (place) => {
    setInput(place.place); // Set input to selected place

    const placeDetails = await fetchPlaceDetails(place.placeId);

    if (placeDetails) {
      console.log(placeDetails);
      onSelectPlace({
        ...place,
        ...placeDetails,
      });
    }
    setSuggestions([]);
  };

  useEffect(() => {
    const places = suggestions.map((suggestion) => ({
      place: suggestion.placePrediction.text.text,
      placeId: suggestion.placePrediction.placeId,
    }));
    setList(places);
  }, [suggestions]);

  return (
    <div className="relative">
      <input
        type="text"
        value={input}
        onChange={handleInputChange}
        placeholder="Enter or select a place"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      />
      {list.length > 0 && (
        <ul className="absolute bg-white border border-gray-300 mt-1 max-h-60 w-full overflow-y-auto shadow-lg rounded-md z-10">
          {list.map((place) => (
            <li
              key={place.placeId}
              onClick={() => handleSelect(place)}
              className="cursor-pointer p-2 hover:bg-blue hover:text-white text-left border-b"
            >
              {place.place}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PlaceAutocomplete;
