import React from "react";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaFacebook,
} from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";

const Footer = () => {
  return (
    <div
      className="footer-container w-full mt-16"
      style={{ background: "#14212A" }}
    >
      <div className="footer-wrapper w-10/12 mx-auto py-12">
        <div className="footer-head  flex gap-8 border-b-2 pb-5 justify-around">
          {/* {ContactData.map((item, index) => (
            <ContactCard
              key={index}
              name={item.name}
              value={item.value}
              icon={item.icon}
            />
          ))} */}
          <div className="flex items-center gap-4 footer-head-contant">
            <div className="bg-yellow-500 p-4 rounded-full footer-icon">
              <FaMapMarkerAlt
                size={20}
                className="text-white footer-icon-main"
              />
            </div>
            <div className="flex flex-col">
              <h3 className="text-xl font-semibold mt-4 text-left text-white footer-heading-text">
                Location
              </h3>
              <p className="text-gray-500 text-left footer-content-text">
                Nizampet, Kukatpally, Telangana, India
              </p>
            </div>
          </div>
          <div className="flex items-center gap-4 footer-head-contant">
            <div className="bg-yellow-500 p-4 rounded-full footer-icon">
              <FaPhoneAlt size={20} className="text-white footer-icon-main" />
            </div>
            <div className="flex flex-col">
              <h3 className="text-xl font-semibold mt-4 text-left text-white footer-heading-text">
                Phone Number
              </h3>
              <p className="text-gray-500 footer-content-text">063057 03206</p>
            </div>
          </div>
          <div className="flex items-center gap-4 footer-head-contant">
            <div className="bg-yellow-500 p-4 rounded-full footer-icon">
              <FaEnvelope size={20} className="text-white footer-icon-main" />
            </div>
            <div className="flex flex-col">
              <h3 className="text-xl font-semibold mt-4 text-left text-white footer-heading-text">
                Email Address
              </h3>
              <p className="text-gray-500 text-xs footer-content-text">
                krpackersandmovers369@gmail.com
              </p>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-6 flex flex-col md:flex-row md:justify-between gap-20 mt-8 justify-between">
          {/* Company Info */}

          <div className="flex-1">
            <div className="flex  mb-4">
              {/* Company Logo */}
              <img
                src="/assets/images/Logo.png"
                alt="MoversCO"
                className="mr-2 w-20 h-20"
              />
            </div>
            <p className="text-sm mb-4 text-white text-left">
              Trusted packers and movers providing secure relocation across
              South Indian states with timely deliveries and comprehensive
              support for homes and offices.
            </p>
            {/* Social Media Icons */}
            {/* <div className="flex space-x-4">
              <a href="#" className="bg-[#14212A] p-3 rounded-full">
                <img src="./assets/icons/facebook.svg" alt="Facebook" />
              </a>
              <a href="#" className="bg-[#14212A] p-3 rounded-full">
                <img src="./assets/icons/twitter.svg" alt="Twitter" />
              </a>
              <a href="#" className="bg-[#14212A] p-3 rounded-full">
                <img src="./assets/icons/instagram.svg" alt="Instagram" />
              </a>
            </div> */}
          </div>
          {/* Quick Links */}
          <div className="flex-1  justify-start flex flex-col align-baseline footer-container-contant">
            <h3 className="text-lg font-bold mb-4 text-white text-left">
              Quick Links
            </h3>
            <ul className="space-y-2 text-white text-left">
              <li>
                <a href="#" className="hover:underline">
                  About Us
                </a>
              </li>
              <li>
                <a href="#services" className="hover:underline">
                  Our Services
                </a>
              </li>
              <li>
                <a href="/contact" className="hover:underline">
                  Get In Touch
                </a>
              </li>
              {/* <li>
                <a href="#" className="hover:underline">
                  Our Clients
                </a>
              </li> */}
              {/* <li>
                <a href="#" className="hover:underline">
                  Download Brochure
                </a>
              </li> */}
            </ul>
          </div>
          {/* Categories */}
          <div className="flex-1 flex flex-col footer-container-contant">
            <h3 className="text-lg font-bold mb-4 text-white text-left">
              Hospital Shifting
            </h3>
            <ul className="space-y-2 text-white text-left">
              <li>
                <a href="#" className="hover:underline">
                  Office shifting
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  House shifting
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Transportation
                </a>
              </li>
              {/* <li>
                <a href="#services" className="hover:underline">
                  Transportation
                </a>
              </li> */}
              {/* <li>
                <a href="#" className="hover:underline">
                  Commodity
                </a>
              </li> */}
            </ul>
          </div>
          {/* Newsletter */}
          <div className="flex-1 footer-container-contant">
            {/* Social Media Icons */}
            <h3 className="text-lg font-bold mb-4 text-white text-left">
              Social Links
            </h3>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/p/KR-Packers-Movers-100094399163205/?_rdr"
                className="bg-[#14212A] p-3 rounded-full"
              >
                <FaFacebook color="white" size={30} />
              </a>
              {/* <a href="#" className="bg-[#14212A] p-3 rounded-full">
                <FaInstagram color="white" size={30} />
              </a>
              <a href="#" className="bg-[#14212A] p-3 rounded-full">
                <FaTwitter color="white" size={30} />
              </a> */}
              <a
                href="https://www.instagram.com/krlogistics369/"
                className="bg-[#14212A] p-3 rounded-full"
              >
                <FaSquareInstagram color="white" size={30} />
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="footer-bottom text-center text-white mt-14">
          <h1>© 2024 Your Company. All rights reserved.</h1>
        </div>
      </div>
    </div>
  );
};

export default Footer;
