import React from "react";

const image_1 = "./assets/images/founder.png";
const image_2 = "./assets/images/driver-1.png";
const image_3 = "./assets/images/driver-2.png";
const image_4 = "./assets/images/helper.png";

const MemberCard = ({ image, role, title }) => {
  return (
    <div className="member-card flex flex-col w-1/4">
      <div className="w-full relative mx-auto h-96 overflow-hidden mb-4 bg-gray-800 image-card">
        <img
          src={image}
          alt={title}
          className="w-full h-full relative z-0 transition-all duration-300 hover:scale-110 object-cover bg-blue-gray-400"
        />
      </div>
      <p className="text-gray-500 text-left">{role}</p>
      <h4 className="text-xl font-semibold text-left uppercase">{title}</h4>
    </div>
  );
};

const Team = () => {
  return (
    <div className="team-container my-16 w-full">
      <div className="team-wrapper w-10/12 mx-auto">
        <h3 className="text-lg border-b-2 uppercase border-primary w-fit mx-auto mb-6">
          Professional Team
        </h3>

        <h2 className="text-4xl w-fit mx-auto mb-12 team-container-heading">
          Meet Our <strong>Team Member!</strong>
        </h2>

        <div className="teamcard-container flex w-full">
          <MemberCard image={image_1} role="Founder" title="Pavan" className='member-card' />
          <MemberCard image={image_2} role="Driver" title="Bikshapati" className='member-card' />
          <MemberCard image={image_3} role="Driver" title="Sridhar" className='member-card' />
          <MemberCard image={image_4} role="Helper" title="Kumar" className='member-card' />
        </div>
      </div>
    </div>
  );
};

export default Team;
