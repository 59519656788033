import React from "react";
import Banner from "../../../Assests/background banner.jpg";

const ServicesMain = () => {
  return (
    <div className="relative mt-[92px] h-[330px] bg-[#FFDC39] bg-cover bg-center services-main-container">
      <div className="absolute inset-0 ">
        <div className="Services-banner">
        <img
          src={Banner}
          alt="banner"
          className="w-full mb-5 banner-services-cointainer"
        /></div>
        <div className="absolute top-[40%] left-[12%] flex flex-col text-left text-black">
          <h1 className="text-[36px] mb-[13px]">Services</h1>
          <p className="text-[16px] cursor-pointer font-medium text-[#777] hover:text-black">
            Making Moving Simple, Every Time. <strong>Services</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesMain;
