import React, { useEffect } from 'react';
import ContactMain from "./ContactMain";
import ContactForm from "./ContactForm";

const Contact = () => {
  useEffect(() => {
    document.title = "KR Logistics-Contact Us";
  }, []);
  return (
    <div>
      <div>
        <ContactMain />
      </div>
      <div>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
