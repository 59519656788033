import React from "react";

const shipment_1 = "./assets/icons/shipment-1.svg";
const shipment_2 = "./assets/icons/shipment-2.svg";
const shipment_3 = "./assets/icons/shipment-3.svg";
const shipment_4 = "./assets/icons/shipment-4.svg";
const step_1 = "./assets/icons/step-01.png";
const step_2 = "./assets/icons/step-02.png";

const StepCard = ({ stepNumber, title, description, icon, image, step }) => (
  <div className="flex flex-col  text-left shipment-wrap">
    <div className="flex justify-between mb-4 items-center">
      <img src={image} alt="shipment-image" className="w-24 h-24" />
      {step ? (
        <img
          src={step}
          alt="status-icon"
          className="hidden md:block w-24 h-4"
        />
      ) : (
        ""
      )}
    </div>
    <div className="flex gap-2 items-center mb-4">
      <div className="bg-yellow-500 text-white font-bold  w-8 h-8 flex items-center justify-center">
        {stepNumber}
      </div>
      <h3 className="text-lg font-semibold">{title}</h3>
    </div>
    <p className="text-gray-500">{description}</p>
  </div>
);

const ShipmentData = () => {
  return (
    <div className="shipment-container mt-40 w-full md:mt-10">
      <div className="shipment-wrapper w-10/12 flex mx-auto gap-10 py-7  ">
        <StepCard
          stepNumber="01"
          title="Give us a Details"
          description="Give us the details we need for a smooth move!"
          image={shipment_1}
          step={step_1}
          icon={<i className="fas fa-box fa-2x"></i>}
        />
        <StepCard
          stepNumber="02"
          title="Provide a Quote"
          description="Provide a quote for your hassle-free move."
          image={shipment_2}
          step={step_2}
          icon={<i className="fas fa-truck-loading fa-2x"></i>}
        />

        <StepCard
          stepNumber="03"
          title="Confirm Your Date"
          description="Confirm your date for a stress-free move!"
          image={shipment_3}
          step={step_1}
          icon={<i className="fas fa-calendar-check fa-2x"></i>}
        />
        <StepCard
          stepNumber="04"
          title="Move Easy & Stress-Free"
          description="Move easy and stress-free with our experts!"
          image={shipment_4}
          icon={<i className="fas fa-shipping-fast fa-2x"></i>}
        />
      </div>
    </div>
  );
};

export default ShipmentData;
