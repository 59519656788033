import React, { useState, useEffect } from "react";
import "./style.css";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State to manage mobile menu visibility
  const currentPath = window.location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header
      className={`header-container fixed w-full top-0 left-0 z-50 transition-all duration-300 ease-in-out ${
        isScrolled
          ? "bg-white text-gray-900 shadow-md"
          : "bg-[#FFDC39] text-gray-900"
      }`}
    >
      <div className="header-wrapper mx-auto flex justify-between items-center p-2 w-4/5 transition">
        {/* Logo */}
        <div className="flex items-center gap-4 header-logo-contant">
          <div className="text-xl font-bold logo-container flex">
            <a href="/" className="text-gray-800">
              <img src="/assets/images/Logo.png" alt="logo" />
            </a>
          </div>
          <h2 className="text-2xl font-semibold" style={{ color: "#0049B6" }}>
            Packers and Movers
          </h2>
        </div>

        {/* Desktop Menu Items */}
        <nav className="nav-container hidden md:flex space-x-6">
          <a
            href="/"
            className={`font-semibold text-base ${
              isScrolled
                ? currentPath === "/"
                  ? "text-[#FFDC39]"
                  : "text-gray-900"
                : "text-gray-900"
            }`}
          >
            Home
          </a>
          <a
            href="/About"
            className={`font-semibold text-base ${
              isScrolled
                ? currentPath === "/About"
                  ? "text-[#FFDC39]"
                  : "text-gray-900"
                : "text-gray-900"
            }`}
          >
            About
          </a>
          <a
            href="/services"
            className={`font-semibold text-base ${
              isScrolled
                ? currentPath === "/services"
                  ? "text-[#FFDC39]"
                  : "text-gray-900"
                : "text-gray-900"
            }`}
          >
            Services
          </a>
          <a
            href="/contact"
            className={`font-semibold text-base ${
              isScrolled
                ? currentPath === "/contact"
                  ? "text-[#FFDC39]"
                  : "text-gray-900"
                : "text-gray-900"
            }`}
          >
            Contact
          </a>
        </nav>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden text-gray-600 hover:text-gray-800 focus:outline-none"
          onClick={toggleMobileMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <nav className="md:hidden bg-white shadow-md py-4">
          <div className="flex flex-col items-center space-y-4">
            <a
              href="/"
              className={`font-semibold text-base ${
                currentPath === "/" ? "text-[#FFDC39]" : "text-gray-900"
              }`}
              onClick={toggleMobileMenu}
            >
              Home
            </a>
            <a
              href="/About"
              className={`font-semibold text-base ${
                currentPath === "/About" ? "text-[#FFDC39]" : "text-gray-900"
              }`}
              onClick={toggleMobileMenu}
            >
              About
            </a>
            <a
              href="/services"
              className={`font-semibold text-base ${
                currentPath === "/services" ? "text-[#FFDC39]" : "text-gray-900"
              }`}
              onClick={toggleMobileMenu}
            >
              Services
            </a>
            <a
              href="/contact"
              className={`font-semibold text-base ${
                currentPath === "/contact" ? "text-[#FFDC39]" : "text-gray-900"
              }`}
              onClick={toggleMobileMenu}
            >
              Contact
            </a>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;
