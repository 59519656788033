import React from "react";

const WhyChoose = () => {
  const shipment_1 = "./assets/icons/shipment-1.svg";
  const shipment_2 = "./assets/icons/shipment-2.svg";
  const shipment_3 = "./assets/icons/shipment-3.svg";
  const shipment_4 = "./assets/icons/shipment-4.svg";

  return (
    <div className=" py-16 px-8" id="services">
      <div className="text-center mb-8">
        <h3 className="text-lg border-b-2 uppercase border-primary w-fit mx-auto mb-6">
          Why Choose Us?
        </h3>
        <h2 className="text-2xl sm:text-4xl font-bold mb-2 sm:mb-12">
          We give you our <span className="text-primary">shipment.</span>
        </h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8 p-3">
        <div className="bg-gray-800 p-6 sm:p-8 text-center why-choose-card">
          <div className="text-yellow-400 mb-4">
            <div className="flex justify-center items-center mb-6 sm:mb-8">
              <img
                src={shipment_3}
                alt="shipment-image"
                className="w-20 h-16 sm:w-24 sm:h-20 filter brightness-0 invert"
              />
            </div>
          </div>
          <h4 className="text-yellow-400 text-md sm:text-lg font-semibold">
            Residential Moving
          </h4>
          <h5 className="text-white text-xl font-bold mt-2">House shifting</h5>
          <p className="text-gray-400 mt-4">
            Seamless relocation for your cherished home and belongings.
          </p>
          <button className="mt-4 bg-yellow-400 text-black px-4 py-2">
            <a href="/booking/house-shifting"> Book Now </a>
          </button>
        </div>

        <div className="bg-gray-800 p-6 sm:p-8 text-center why-choose-card">
          <div className="text-yellow-400 mb-4">
            <div className="flex justify-center items-center mb-6 sm:mb-8">
              <img
                src={shipment_2}
                alt="shipment-image"
                className="w-20 h-16 sm:w-24 sm:h-20 filter brightness-0 invert"
              />
            </div>
          </div>
          <h4 className="text-yellow-400 text-md sm:text-lg font-semibold">
            Local Moving
          </h4>
          <h5 className="text-white text-xl font-bold mt-2">Office shifting</h5>
          <p className="text-gray-400 mt-4">
            Streamlined office transitions for minimal business disruption.
          </p>
          <button className="mt-4 bg-yellow-400 text-black px-4 py-2 ">
            <a href="/booking/office-shifting"> Book Now </a>
          </button>
        </div>

        <div className="bg-gray-800 p-6 sm:p-8 text-center why-choose-card">
          <div className="text-yellow-400">
            <div className="flex justify-center items-center mb-6 sm:mb-8">
              <img
                src={shipment_1}
                alt="shipment-image"
                className="w-20 h-16 sm:w-24 sm:h-20 filter brightness-0 invert"
              />
            </div>
          </div>
          <h4 className="text-yellow-400 text-md sm:text-lg font-semibold">
            Commercial Movers
          </h4>
          <h5 className="text-white text-xl font-bold mt-2">
            Hospital Shifting
          </h5>
          <p className="text-gray-400 mt-4">
            Efficient hospital moves ensuring uninterrupted patient care.
          </p>
          <button className="mt-4 bg-yellow-400 text-black px-4 py-2 ">
            <a href="/booking/hospital-shifting"> Book Now </a>
          </button>
        </div>

        <div className="bg-gray-800 p-6 sm:p-8 text-center why-choose-card">
          <div className="text-yellow-400 mb-4">
            <div className="flex justify-center items-center mb-6 sm:mb-8">
              <img
                src={shipment_4}
                alt="shipment-image"
                className="w-20 h-16 sm:w-24 sm:h-20 filter brightness-0 invert"
              />
            </div>
          </div>
          <h4 className="text-yellow-400 text-md sm:text-lg font-semibold">
            International Moving
          </h4>
          <h5 className="text-white text-xl font-bold mt-2">Transportation</h5>
          <p className="text-gray-400 mt-4">
            Reliable and efficient transportation for all your needs.
          </p>
          <button className="mt-4 bg-yellow-400 text-black px-4 py-2  disabled">
            <a href="#"> Coming Soon </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
