import React from "react";
import AboutUs from "./About";
import Aboutuscard from "./Aboutuscard";
import './About.css'
// import AboutVideo from "./AboutVideo";
// import AboutmyCard from "./AboutmyCard";

const About = () => {
  return (
    <div>
      <div>
        <AboutUs />
      </div>
      <div>
        <Aboutuscard />
      </div>
      {/* <div>
        <AboutVideo />
      </div>
      <div>
        <AboutmyCard />
      </div> */}
    </div>
  );
};

export default About;
