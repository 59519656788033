import React, { useEffect } from 'react';
import Banner from "./banner";
import ShipmentData from "./shipmentData";
import Team from "./team";
import Stats from "./stats";
import "./style.css";
import WhyChoose from "./whyChoose";

const Home = () => {
  useEffect(() => {
    document.title = "KR Logistics-Home";
  }, []);
  
  return (
    <>
      <Banner />
      <div className="h-16"></div>
      <ShipmentData />
      <div className="h-16"></div>
      <WhyChoose />
      <div className="h-16"></div>
      <Stats />
      <div className="h-16"></div>
      <Team />
      {/* <div className="h-16"></div> */}
    </>
  );
};

export default Home;
